import React from 'react'
import { COLOR_CONSTANTS } from 'theme'
import App from 'App'
import SEO from 'components/SEO'
import { H1, Text } from '../components/Typography'
import { Box, Flex } from '../components/Layout'
import BlockOfTextWithTableOfContents from '../components/BlockOfTextWithTableOfContents'
import Footer from '../components/Footer'

const content = [
  {
    name: 'introduction',
    title: 'Introduction',
    body: `
      This Privacy Policy governs the manner in which Vista Social collects, uses, maintains and discloses information collected from users (each, a “User”) of the vistasocial.com website (“Site”). This privacy policy applies to the Site and all products and services offered by Vista Social LLC.
      `,
  },
  {
    name: 'personalinformation',
    title: 'Personal identification information',
    body: `
      We may collect personal identification information from Users in a variety of ways, including, but not limited to, when Users visit our site, subscribe to the newsletter, fill out a form, and in connection with other activities, services, features or resources we make available on our Site. Users may be asked for, as appropriate, name, email address, mailing address, phone number. Users may, however, visit our Site anonymously. We will collect personal identification information from Users only if they voluntarily submit such information to us. Users can always refuse to supply personally identification information, except that it may prevent them from engaging in certain Site related activities.
      `,
  },
  {
    name: 'nonpersonalinformation',
    title: 'Non-personal identification information',
    body: `
      We may collect non-personal identification information about Users whenever they interact with our Site. Non-personal identification information may include the browser name, the type of computer and technical information about Users means of connection to our Site, such as the operating system and the Internet service providers utilized and other similar information.
      `,
  },
  {
    name: 'cookies',
    title: 'Web browser cookies',
    body: `
      Our Site may use “cookies” to enhance User experience. User’s web browser places cookies on their hard drive for record-keeping purposes and sometimes to track information about them. User may choose to set their web browser to refuse cookies, or to alert you when cookies are being sent. If they do so, note that some parts of the Site may not function properly.
      `,
  },
  {
    name: 'useinformation',
    title: 'How we use collected information',
    body: `
      Vista Social may collect and use Users personal information for the following purposes:
      <br>
      <br>To improve customer service: Information you provide helps us respond to your customer service requests and support needs more efficiently.
      <br>To improve our Site: We may use feedback you provide to improve our products and services.
      <br>To send periodic emails
      <br><br>
      We may use the email address to send User information and updates pertaining to their order. It may also be used to respond to their inquiries, questions, and/or other requests. If User decides to opt-in to our mailing list, they will receive emails that may include company news, updates, related product or service information, etc. If at any time the User would like to unsubscribe from receiving future emails, we include detailed unsubscribe instructions at the bottom of each email.
      `,
  },
  {
    name: 'protect',
    title: 'How we protect your information',
    body: `
      We adopt appropriate data collection, storage and processing practices and security measures to protect against unauthorized access, alteration, disclosure or destruction of your personal information, username, password, transaction information and data stored on our Site.
      `,
  },
  {
    name: 'sharing',
    title: 'Sharing your personal information',
    body: `
      We do not sell, trade, or rent Users personal identification information to others. We may share generic aggregated demographic information not linked to any personal identification information regarding visitors and users with our business partners, trusted affiliates and advertisers for the purposes outlined above. We may use third party service providers to help us operate our business and the Site or administer activities on our behalf, such as sending out newsletters or surveys. We do not share your information with third parties or AI models unless unless you have given us your permission.
      <br><br>
      Please note that Vista Social uses YouTube API Services as a service provider, and if you use these services through the Service, you are subject to the <a href="https://policies.google.com/privacy">Google Privacy Policy</a>.
      <br><br>
      Vista Social use and transfer to any other app of information received from Google APIs will adhere to <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes" target="_blank" nofollow>Google API Services User Data Policy</a>, including the Limited Use requirements.”
      `,

    // We may share your information with these third parties for those limited purposes provided that you have given us your permission
  },
  {
    name: 'thirdparty',
    title: 'Third party websites',
    body: `
      Users may find advertising or other content on our Site that link to the sites and services of our partners, suppliers, advertisers, sponsors, licensors and other third parties. We do not control the content or links that appear on these sites and are not responsible for the practices employed by websites linked to or from our Site. In addition, these sites or services, including their content and links, may be constantly changing. These sites and services may have their own privacy policies and customer service policies. Browsing and interaction on any other website, including websites which have a link to our Site, is subject to that website’s own terms and policies.
      `,
  },
  {
    name: 'dataprotection',
    title: 'Your Data Protection Rights',
    body: `
    If you wish to access, correct, update or request deletion of your information, you can do so at any time by contacting us using the contact details provided under the <a href="#contact">“Contact”</a> link below. Note that if you submit a request to delete your information, this may prohibit you from using the Site and/or Service. If you have authorized us to access your data via the YouTube API Services, then in addition to our normal procedure for deleting stored data, you may revoke our access to your data via the Google security settings page, located at <a href="https://security.google.com/settings/security/permissions">https://security.google.com/settings/security/permissions</a>.
    <br><br>
    In addition, if you are a resident of the European Union, you can object to processing of your personal data, ask us to restrict processing of your personal data, or request portability of your personal data. Again, you can exercise these rights by contacting us using the contact details provided under the  <a href="#contact">“Contact”</a> link below. Note that if you submit such a request, this may prohibit you from using the Site and/or Service.
    <br><br>
    You have the right to opt-out of marketing communications we send you at any time. You can exercise this right by clicking on the “unsubscribe” or “opt-out” link in the marketing emails we send you. To opt-out of other forms of marketing (such as postal marketing or telemarketing), then please contact us using the contact details provided under the <a href="#contact">“Contact”</a> link below. Please note that your opt out is limited to the email address, device, and phone number used and will not affect subsequent subscriptions.
    <br><br>
    Similarly, if we have collected and processed your information with your consent, then you can withdraw your consent at any time. Withdrawing your consent will not affect the lawfulness of any processing we conducted prior to your withdrawal, nor will it affect processing of your information conducted in reliance on lawful processing grounds other than consent.
    <br><br>
    You have the right to complain to a data protection authority about our collection and use of your information. For more information, please contact your local data protection authority.
    <br><br>
    We respond to all requests we receive from individuals wishing to exercise their data protection rights in accordance with applicable data protection laws. Where we process your information solely on behalf of a customer, we may be legally required to forward your request directly to our customer and/or social media business partners for their review / handling.
  
      `,
  },
  {
    name: 'changes',
    title: 'Changes to this privacy policy',
    body: `
      Vista Social has the discretion to update this privacy policy at any time. When we do, we will revise the updated date at the bottom of this page. We encourage Users to frequently check this page for any changes to stay informed about how we are helping to protect the personal information we collect. You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of modifications.
      `,
  },
  {
    name: 'acceptance',
    title: 'Your acceptance of these terms',
    body: `
      By using this Site, you signify your acceptance of this policy. If you do not agree to this policy, please do not use our Site. Your continued use of the Site following the posting of changes to this policy will be deemed your acceptance of those changes.
      <br/>
      <br/>
      If you have any questions about this Privacy Policy, the practices of this site, or your dealings with this site, please contact us by clicking the <a href="#contact">“Contact”</a> link below.
      `,
  },
]

const PrivacyPolicy = () => (
  <App>
    <SEO title="Privacy Policy" description="Learn more about the privacy policy for Vista Social" path="/privacy/" />
    <Flex mt="xl" flexDirection="column" alignItems="center">
      <H1 color={COLOR_CONSTANTS.DENIM} fontSize="4xl" fontWeight="bold">
        Privacy Policy
      </H1>
      <Text mt="m" fontSize="xs" color="secondaryText">
        Last updated: January 21, 2025
      </Text>
    </Flex>
    <Box m="0 auto" px="m" mt="xl" maxWidth="1080px">
      <BlockOfTextWithTableOfContents content={content} />
    </Box>
    <Footer />
  </App>
)

export default PrivacyPolicy
